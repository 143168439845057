import React from "react"
import FJudgeToolbar from "../components/toolbar.js"

import '../css/global.css'

class Layout extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    const fullwidth = this.props.fullwidth || false;
    const mainclasses = (fullwidth) ? 'w-full mx-4' : 'sm:max-w-80pct md:max-w-70pct mx-auto'
    // const { location, title, children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div className="flex flex-col min-h-screen">
        <FJudgeToolbar />
        <main className={`flex-1 ${mainclasses}`}>{this.props.children}</main>
        <footer className="mt-16">
          <div className="font-rsone text-lg bg-yellow-900 text-yellow-200 text-center w-full py-4">
            The Fantasy Judge © {new Date().getFullYear()}
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
