import React from "react"
import { Link } from "gatsby"
// import toolbarStyles from "./toolbar.module.css"
import toolbarStyles from "../css/toolbar.css"
import { IoLogoFacebook, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io'

const links = [

  { label: "Scoreboard", linkto: "/scores", bg: "bg-green-600", txt: "text-white",
    sublinks: [
      { label: "2020", linkto: "/scores/2020", bg: "bg-green-200"},
      { label: "2019", linkto: "/scores/2019", bg: "bg-green-200"}
    ]
  },
  { label: "Verdicts", linkto: "/verdicts", bg: "bg-green-600", txt: "text-white"},
  // { label: "Analysis", linkto: "/graphs", bg: "bg-green-600", txt: "text-white"},
  { label: "?", linkto: "/help/about", bg: "bg-green-200",
    sublinks: [
      { label: "About", linkto: "/help/about", bg: "bg-green-200"},
      { label: "Scoring", linkto: "/help/scoring", bg: "bg-green-200"},
      { label: "FAQ", linkto: "/help/faq", bg: "bg-green-200"},
      { label: "Experts", linkto: "/help/experts", bg: "bg-green-200"},
      { label: "Contact", linkto: "/help/contact", bg: "bg-green-200"},    
    ]
  }
]

const mobileLinks = [
  { label: "Scoreboard-2020", linkto: "/scores/2020", bg: "bg-green-600", txt: "text-white"},
  { label: "Scoreboard-2019", linkto: "/scores/2019", bg: "bg-green-600", txt: "text-white"},
  { label: "Verdicts", linkto: "/verdicts", bg: "bg-green-600", txt: "text-white"},
  // { label: "Analysis", linkto: "/graphs", bg: "bg-green-600", txt: "text-white"},
  { label: "About", linkto: "/help/about", bg: "bg-green-200"},
  { label: "Scoring", linkto: "/help/scoring", bg: "bg-green-200"},
  { label: "FAQ", linkto: "/help/faq", bg: "bg-green-200"},
  { label: "Experts", linkto: "/help/experts", bg: "bg-green-200"},
  { label: "Contact", linkto: "/help/contact", bg: "bg-green-200"},
]

const ToolbarLink = (props) => (
  <div className={`${(props.special || []).includes('dropdown') ? 'px-8' : 'px-2'} hover:bg-yellow-600`}>
    <Link
      to={props.linkto}
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
      }}
    >{props.label}</Link>
  </div> 
) 

class FJudgeToolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileMenu: false
    }
  }

  handleClick = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu })
  }

  render() {
    return (
      <header classnamex={`${toolbarStyles.toolbar}`} 
        className="toolbar bg-yellow-500 text-yellow-900 border-solid border-b-2 border-yellow-900 
              flex flex-col sm:flex-row sm:justify-between 
              font-rsone text-2xl md:text-3xl"
      >   
        <div className="logo w-full sm:w-auto sm:ml-4 text-center sm:text-left">
          <ul className="block flex flex-row mr-4">
            <li className="mr-2 sm:mr-6"><ToolbarLink label="The Fantasy Judge!" linkto="/" /></li>
            <li className="mr-2 mt-2"><a href="https://twitter.com/fjudge_com" target="_blank" rel="noopener noreferrer"><IoLogoTwitter /></a></li>
            <li className="mr-2 mt-2"><a href="https://www.instagram.com/fjudge_com/" target="_blank" rel="noopener noreferrer"><IoLogoInstagram /></a></li>
            <li className="mt-2"><a href="https://www.facebook.com/thefantasyjudge" target="_blank" rel="noopener noreferrer"><IoLogoFacebook /></a></li>
          </ul>          
          <span onClick={this.handleClick} className={`sm:hidden absolute top-0 right-0 mr-1 font-sans text-bold ${this.state.showMobileMenu ? 'menu-button-on' : 'menu-button-off'}`}></span>
        </div>

        {/* <div className="desktopmenu links">
          <ul className="hidden sm:block sm:flex sm:flex-row sm:mr-4">
            <li><ToolbarLink label="The Fantasy Judge!" linkto="/" /></li>
            <li><a href="https://twitter.com/fjudge_com">tw</a></li>
            <li><a href="https://www.instagram.com/fjudge_com/">ig</a></li>
            <li><a href="https://www.facebook.com/thefantasyjudge">fb</a></li>
          </ul>
        </div> */}
        
        {/* mobile menu -- hidden (display none) on big screens */}
        <div className={`mobilemenu w-full text-center m-0 p-0 sm:hidden ${this.state.showMobileMenu ? 'on' : ''}`}>
          <ul className="bg-green-200">
          {mobileLinks.map((val, idx) => {
            return (
              <li className={`my-0 ${val.bg} ${val.txt || ''}`} key={idx}>
                <ToolbarLink label={val.label} linkto={val.linkto} />
              </li>
            )
          })}
          </ul>
        </div>

        {/* desktop menu -- hidden (display none) on mobile screens */}
        <div className="desktopmenu links">
          <ul className="hidden sm:block sm:flex sm:flex-row sm:mr-4">
          {links.map((link, idx) => {
            return ( 
              <li key={idx} className={`inline my-0 ${idx === (links.length - 1) ? '' : 'pr-4'}`}>
                <ToolbarLink label={link.label} linkto={link.linkto} />
                {(link['sublinks'] || []) && 
                  <ul className={`dropdown border-solid border-t-2 border-yellow-900 ${link.label == '?' ? 'right-0' : ''}`}>
                    {(link['sublinks'] || []).map((sublink, idx) => {
                      return (
                        <li key={idx} className={`bg-yellow-500`}>
                          <ToolbarLink label={sublink.label} linkto={sublink.linkto} special={['dropdown']} />
                        </li>
                      )
                    })}
                  </ul>
                }
              </li>
            )
          })}
          </ul>
        </div>
      </header>
    )
  }
}

export default FJudgeToolbar
