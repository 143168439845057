/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import faviconico from "../../content/assets/icons/favicon.ico";
import favicon16 from "../../content/assets/icons/favicon-16x16.png";
import favicon32 from "../../content/assets/icons/favicon-32x32.png";
import favicon48 from "../../content/assets/icons/favicon-48x48.png";
import favicon64 from "../../content/assets/icons/favicon-64.png";
import favicon96 from "../../content/assets/icons/favicon-96x96.png";
import favicon128 from "../../content/assets/icons/favicon-128.png";
import favicon160 from "../../content/assets/icons/favicon-160.png";
import favicon192 from "../../content/assets/icons/favicon-192.png";

import apple57 from "../../content/assets/icons/apple-touch-icon-57x57.png";
import apple72 from "../../content/assets/icons/apple-touch-icon-72x72.png";
import apple114 from "../../content/assets/icons/apple-touch-icon-114x114.png";
import apple120 from "../../content/assets/icons/apple-touch-icon-120x120.png";
import apple144 from "../../content/assets/icons/apple-touch-icon-144x144.png";
import apple152 from "../../content/assets/icons/apple-touch-icon-152x152.png";



function SEO({ description, lang, meta, title, structured }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: 'fj-body'
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        { rel: 'shortcut icon', sizes: "16x16", href: `${faviconico}` },
        { rel: 'icon', sizes: "16x16 32x32 64x64", href: `${faviconico}` },
        { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${favicon16}` },
        { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${favicon32}` },
        { rel: 'icon', type: 'image/png', sizes: "48x48", href: `${favicon48}` },
        { rel: 'icon', type: 'image/png', sizes: "64x64", href: `${favicon64}` },
        { rel: 'icon', type: 'image/png', sizes: "96x96", href: `${favicon96}` },
        { rel: 'icon', type: 'image/png', sizes: "128x128", href: `${favicon128}` },
        { rel: 'icon', type: 'image/png', sizes: "160x160", href: `${favicon160}` },
        { rel: 'icon', type: 'image/png', sizes: "192x192", href: `${favicon192}` },
        { rel: 'apple-touch-icon-precomposed', sizes: "57x57", href: `${apple57}` },
        { rel: 'apple-touch-icon-precomposed', sizes: "72x72", href: `${apple72}` },
        { rel: 'apple-touch-icon-precomposed', sizes: "114x114", href: `${apple114}` },
        { rel: 'apple-touch-icon-precomposed', sizes: "120x120", href: `${apple120}` },
        { rel: 'apple-touch-icon-precomposed', sizes: "144x144", href: `${apple144}` },
        { rel: 'apple-touch-icon-precomposed', sizes: "152x152", href: `${apple152}` },
      ]}
    >
    { structured || '' }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  structured: null
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
