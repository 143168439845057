// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-staticpage-js": () => import("/opt/build/repo/src/templates/staticpage.js" /* webpackChunkName: "component---src-templates-staticpage-js" */),
  "component---src-templates-verdict-js": () => import("/opt/build/repo/src/templates/verdict.js" /* webpackChunkName: "component---src-templates-verdict-js" */),
  "component---src-templates-indextemplate-js": () => import("/opt/build/repo/src/templates/indextemplate.js" /* webpackChunkName: "component---src-templates-indextemplate-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scores-2019-index-js": () => import("/opt/build/repo/src/pages/scores/2019/index.js" /* webpackChunkName: "component---src-pages-scores-2019-index-js" */),
  "component---src-pages-scores-2020-index-js": () => import("/opt/build/repo/src/pages/scores/2020/index.js" /* webpackChunkName: "component---src-pages-scores-2020-index-js" */),
  "component---src-pages-scores-index-js": () => import("/opt/build/repo/src/pages/scores/index.js" /* webpackChunkName: "component---src-pages-scores-index-js" */),
  "component---src-pages-help-experts-index-mdx": () => import("/opt/build/repo/src/pages/help/experts/index.mdx" /* webpackChunkName: "component---src-pages-help-experts-index-mdx" */)
}

