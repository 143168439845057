import React from "react"
// import {Helmet} from "react-helmet"

import Layout from "../components/layout" 
import SEO from "../components/seo"

import twitterlogo from "../../content/assets/twitter_sq.svg"

// const imgstyle = 'height: 1.3em'

export const Twitter = (props) => (
  <span class="logos">
    <a href={`https://www.twitter.com/${props.handle}`} rel="nofollow"><img src={twitterlogo} className="logo-tw" alt="Twitter Logo" /></a>
  </span>
)

const nofollow = [
  {
    name: `robots`,
    content: `index, nofollow`,
  },
  {
    name: `googlebot`,
    content: `index, nofollow`,
  }
]

export default ({ children }) => (
  <Layout>
    <SEO meta={nofollow} />
    <article className="markdown font-gudea mx-2 sm:mx-auto">
      {children}
    </article>
  </Layout>
)
